import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import favicon from '../images/favicon.ico';

const Layout = ({ backgroundColor, children, pageTitle }) => {
  const bodyBackgroundColor = !backgroundColor || backgroundColor === 'default' ? '#FAFAFF' : backgroundColor;

  const returnNav = () => {
    if (pageTitle !== 'Home')
      return (
        <nav style={{ backgroundColor: bodyBackgroundColor }}>
          <span>Joe Oak</span>
          <span className='page-title'>{pageTitle}</span>
          <Link to='/'>Back home</Link>
        </nav>
      );
  };

  return (
    <>
      <Helmet>
        <html lang='en' />
        <link rel='icon' href={favicon} />
        <title>{pageTitle} - Joe Oak</title>
        <style>
          {`
            body {
              background-color: ${bodyBackgroundColor};
            }
          `}
        </style>
      </Helmet>
      {returnNav()}
      <div className='content'>{children}</div>
    </>
  );
};

export default Layout;
